<template>
  <div class="customer-accounts-rider-sidebar">
    <div class="customer-accounts-rider-sidebar--content">
      <div v-if="isModeDelete">
        <p>Are you sure you want to delete this rider?</p>
      </div>
      <v-form ref="rider-form" v-else>
        <CRInput
          id="rider-email"
          v-model="email"
          label="Email"
          placeholder="Email"
          type="text"
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Email is Required',
              error: 'Email is Required',
            }),
          ]"
          :disabled="isModeEdit"
        />
        <v-layout row>
          <v-flex xs6>
            <CRInput
              v-model="firstName"
              label="First Name"
              placeholder="First Name"
              type="text"
              hide-details
            />
          </v-flex>
          <v-flex class="margin-l-4" xs6>
            <CRInput
              v-model="lastName"
              label="Last Name"
              placeholder="Last Name"
              type="text"
              hide-details
            />
          </v-flex>
        </v-layout>
        <CRInput
          v-model="phone"
          label="Phone"
          placeholder="Phone"
          type="text"
          hide-details
        />
        <CRSelect
          v-model="selectedRiderGroupIds"
          label="Rider Groups"
          placeholder="Select rider groups"
          item-text="name"
          item-value="id"
          multiple
          hide-details
          :items="riderGroups"
        />
        <v-checkbox
          :input-value="sendInviteEmail"
          label="Send Invite Email"
          class="shrink mt-0"
          solo
          hide-details
        />
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="customer-accounts-rider-save-btn"
        :loading="loading || submitting"
        class="customer-accounts-rider-sidebar--action-btn"
        :color="mode === 'delete' ? 'red' : 'primary'"
        @click="submit"
      >
        {{ isModeDelete ? 'Delete' : 'Save' }}
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import customerAccounts from '@/services/customerAccounts'
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {
    customerAccountId: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: 'edit',
    },
    rider: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeDelete() {
      return this.mode === 'delete'
    },
  },
  data() {
    return {
      riderGroups: [],
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      sendInviteEmail: false,
      selectedRiderGroupIds: [],
      loading: false,
      submitting: false,
    }
  },
  async mounted() {
    const riderGroupResponse = await customerAccounts.getRiderGroups(
      this.customerAccountId
    )
    this.riderGroups = riderGroupResponse.data
    this.riderGroups = this.riderGroups.filter((rg) => !rg.isDefaultGroup)
    if (this.isModeEdit) {
      this.email = this.rider.email
      this.firstName = this.rider.firstName
      this.lastName = this.rider.lastName
      this.phone = this.rider.phone
      this.selectedRiderGroupIds = this.rider.riderGroups.map((rg) => rg.id)
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    isRequired,
    isNotEmpty,
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      if (!this.isModeDelete && !this.$refs['rider-form'].validate()) {
        return
      }
      this.submitting = true

      const payload = {
        email: this.email,
        customerAccountId: this.customerAccountId,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        riderGroupIds: this.selectedRiderGroupIds,
        sendInviteEmail: this.sendInviteEmail,
      }

      try {
        if (this.isModeAdd) {
          const addRiderResponse = await customerAccounts.createRider(payload)
        }
        if (this.isModeEdit) {
          payload.riderId = this.rider.id
          const updateRiderResponse = await customerAccounts.updateRider(
            payload
          )
        } else if (this.isModeDelete) {
          payload.riderId = this.rider.id
          const deleteRiderResponse = await customerAccounts.deleteRider(
            this.customerAccountId,
            this.rider.id
          )
        }
        EventBus.$emit('global-table-view-refresh')
        let message = ''
        switch (this.mode) {
          case 'add':
            message = 'Rider successfully added.'
            break
          case 'edit':
            message = 'Rider successfully updated.'
            break
          case 'delete':
            message = 'Rider successfully deleted.'
            break
        }
        this.$nextTick(() => {
          this.showAlert({
            type: 'success',
            message: message,
          })
        })
      } catch (e) {
        let message = ''
        switch (this.mode) {
          case 'add':
            message = 'Error adding rider'
            break
          case 'edit':
            message = 'Error updating rider'
            break
          case 'delete':
            message = 'Error deleting rider'
            break
        }
        this.showAlert({
          type: 'error',
          message: message,
        })
        console.error(e)
        this.submitting = false
        return
      }

      this.submitting = false
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-accounts-rider-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
